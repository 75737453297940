<template>
    <div class="" v-allow:auth="'client.read'">
        <SubHeader buttonText="Create client" :handleClick="checkPermission('client.create') && createNewClient" :showBtn="checkPermission('client.create')" />
        <div class="relative">
            <vue-good-table id="clientList" :rows="getFilteredResult" :columns="columns" :isLoading="isLoading" @on-per-page-change="onPageChange($event, true)"
                @on-page-change="onPageChange($event)" :pagination-options="paginationOptions" :totalRows="pageData?.total" :key="pageData?.total">
                <template #column-filter="{ column }" class="bg-white">
                    <Dropdown
                        reposition
                        searchable
                        :multiple="column.filterOptions.multi || false"
                        class="filter-dropdown"
                        placeholder="Select"
                        v-if="column.filterOptions && column.filterOptions.enabled"
                        :options="column.filterOptions.filterDropdownItems"
                        :value="getSelectedOptions(column)"
                        @input="(value) => updateColumnFilters(column, value)"
                        @search="searchFilter(column, $event)"
                        :limit="1"
                        maxWidth="100%"
                        width="100px"
                        minWidth="100%"
                        :config="checkColumnConfig(column)"
                        :loading="column.filterOptions.isLoading"
                        :taggable="false"
                        :preserve-search="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :showCheckBox="column.filterOptions.multi || false"
                        :selectedOptions="selectedOptions[column.label]"
                        :isTableLargeDropdown="true"
                    >
                    </Dropdown>
                </template>
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'name'" :class="[props.row.system_default && 'default hover-text']" class="flex gap-3">
                        <router-link
                            :to="{
                                name: 'client-details',
                                params: { clientId: props.row.id },
                            }"
                            class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
                            >{{ props.row.name }}</router-link
                        >
                    </div>
                    <div v-else-if="props.column.field === 'allocated_teams'" class="flex gap-3">
                        <button @click.stop="displayWorkAllocationModal('teams', props.row.id)" class="flex flex-col gap-1 items-center relative cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" :fill="props.row && props.row.work_allocation && props.row.work_allocation.groups > 0 ? 'rgba(59, 130, 246,1)' : 'gray'" viewBox="0 0 640 512">
                                <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                    d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"
                                />
                            </svg>
                            <span class="text-gray-500 text-2xs font-semibold">Teams</span>
                            <span v-if="props.row && props.row.work_allocation && props.row.work_allocation.groups > 0" class="absolute -top-3 -right-2 w-5 h-5 text-2xs flex justify-center items-center rounded-full bg-blue-400 p-2 text-white-text">
                                {{ checkIfAllocationValueIsLarge(props.row.work_allocation.groups) }}
                            </span>
                        </button>
                    </div>

                    <div v-else-if="props.column.field === 'allocated_users'" class="flex gap-3">
                        <button @click.stop="displayWorkAllocationModal('users', props.row.id)" class="flex flex-col gap-1 items-center relative ml-10 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" :fill="props.row && props.row.work_allocation && props.row.work_allocation.users > 0 ? 'rgba(59, 130, 246,1)' : 'gray'" viewBox="0 0 448 512">
                                <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M224 256A128 128 0 1 1 224 0a128 128 0 1 1 0 256zM209.1 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2H224h19.7c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 33.4 123.9 36-146.9c2-8.1 9.8-13.4 17.9-11.3c70.1 17.6 121.9 81 121.9 156.4c0 17-13.8 30.7-30.7 30.7H285.5c-2.1 0-4-.4-5.8-1.1l.3 1.1H168l.3-1.1c-1.8 .7-3.8 1.1-5.8 1.1H30.7C13.8 512 0 498.2 0 481.3c0-75.5 51.9-138.9 121.9-156.4c8.1-2 15.9 3.3 17.9 11.3l36 146.9 33.4-123.9z" />
                            </svg>
                            <span class="text-gray-500 text-2xs font-semibold">Users</span>
                            <span v-if="props.row && props.row.work_allocation && props.row.work_allocation.users > 0" class="absolute -top-3 -right-2 w-5 h-5 text-2xs flex justify-center items-center rounded-full bg-blue-400 p-2 text-white-text">
                                {{ checkIfAllocationValueIsLarge(props.row.work_allocation.users) }}
                            </span>
                        </button>
                    </div>
                    <div v-else-if="props.column.field === 'id'" class="flex gap-3">
                        {{ props.row.id.substring(0, 8) }}
                    </div>
                </template>
                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
                <template #emptystate>
                    <Empty text="No Data found" v-if="!isLoading && !clients.length" />
                    <div v-else class="py-16"></div>
                </template>
            </vue-good-table>
            <button v-if="selectedDropDownItems && Object.keys(selectedDropDownItems).length"
                class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute left-2 top-2 z-50" 
                @click="clearFilters">
                Clear filters
            </button>
        </div>
        <DisplayWorkAllocationModal :workData="activeWorkAllocation" ref="work-allocation" :isLoading="fetchingWorkAllocation" />
    </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import Loader from "@shared/loader";
import DisplayWorkAllocationModal from "@/components/create-client/displayWorkAllocationModal";
import { checkPermission } from "@shared/utils/functions";
import axios from "@/axios";
import Empty from "@shared/empty";
import Dropdown from "@shared/components/dropdown-base";
import { checkColumnConfig, prePopulatedDropdownItems } from "@shared/utils/functions";

export default {
    name: "client-list",
    components: {
        SubHeader,
        Loader,
        DisplayWorkAllocationModal,
        Empty,
        Dropdown,
    },
    data() {
        return {
            columns: [
                {
                    label: "Client name",
                    field: "name",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "name",
                    },
                },
                {
                    label: "Prefix",
                    field: "prefix",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "prefix",
                    },
                },
                {
                    label: "Allocated Team",
                    field: "allocated_teams",
                    sortable: false, 
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "groups",
                    },
                },
                {
                    label: "Allocated Users",
                    field: "allocated_users",
                    sortable: false, 
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "users",
                    },
                },
                {
                    label: "Retention period",
                    field: "retention_period",
                    sortable: true,
                    sortFn: this.sortRetPeriod,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "retention_period",
                    },
                },
                {
                    label: "Client id",
                    field: "id",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                    },
                },
            ],
            isLoading: false,
            clients: [],
            fetchingWorkAllocation: false,
            activeWorkAllocation: {
                type: null,
                data: null,
            },
            tenantId: null,
            users: [],
            groups: [],

            // Filter
            searchTimeout: 500,
            selectedDropDownItems: null,
            searchParams: null,

            // Pagination
            paginationOptions: {
                enabled: true,
                dropdownAllowAll: false,
                mode: "remote",
                perPage: 10
            },
            pageData: null,
            currentPageData: {
                page: 1,
                count: 10,
            },
            tableKey: 1,
            selectedOptions:{}
        };
    },
    title: "Client List",
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenantId = this.$store.getters.getTenantId;
        this.isLoading = true;
        await this.getUsersLists();
        await this.getGroups();
        this.clients = await this.fetchClientListsPost();
        this.columns = prePopulatedDropdownItems(
            this.columns.map((el) => {
                if (el.field === "allocated_teams" || el.field === "allocated_users") {
                    return { 
                        ...el, 
                        filterOptions: { 
                            ...el.filterOptions, 
                            customDropdownItems: el.field === "allocated_teams" ? 
                                this.groups?.map(el => ({id:el.id, name:el.title})) : 
                                this.users?.map(el => ({ id: el.id, name: el.user_name })) 
                        } 
                    };
                } else {
                    return el;
                }
            }),
            this.clients
        );
    },
    computed: {
        getFilteredResult() {
            return this.clients;
        },
    },
    methods: {
        checkPermission,
        getSelectedOptions(column){
            return this.selectedOptions[column.label]
        },
        async fetchClients() {
            let result = null;
            try {
                this.isLoading = true;
                const data = await axios.get(`/tenant/${this.tenantId}/client?`, { params: { ...this.searchParams } });
                result = data.data;
            } catch (error) {
                console.log(error, "error");
            }
            this.isLoading = false;
            return result;
        },
        sortRetPeriod(x, y) {
            // x - row1 value for column
            // y - row2 value for column
            // col - column being sorted
            // rowX - row object for row1
            // rowY - row object for row2
            return (x < y ? -1 : (x > y ? 1 : 0));
        },
        createNewClient() {
            this.$router.push({
                name: "create-client",
            });
        },
        async displayWorkAllocationModal(allocationType, client_id) {
            this.$refs["work-allocation"].showWorkModal();
            this.fetchingWorkAllocation = true;
            if (allocationType === "teams") {
                this.activeWorkAllocation = {
                    type: allocationType,
                    data: await this.fetchAllotedTeamsList(client_id),
                };
            } else {
                this.activeWorkAllocation = {
                    type: allocationType,
                    data: await this.fetchALlotedclients(client_id),
                };
            }
            this.fetchingWorkAllocation = false;
            this.$forceUpdate();
        },
        async fetchALlotedclients(client_id) {
            let url = `work-allocation/users/tenant?filter=alloted&&client_id=${client_id}`;
            let users = [];
            try {
                const { data } = await axios.get(url);
                users = data;
            } catch (error) {
                console.log(error, "Error");
            }
            console.log(users, "users");
            return users;
        },
        async fetchAllotedTeamsList(client_id) {
            let url = `work-allocation/groups/tenant?filter=alloted&&client_id=${client_id}`;
            let teams = [];
            try {
                const { data } = await axios.get(url);
                teams = data;
            } catch (error) {
                console.log(error, "Erro");
            }
            console.log(teams, " teams");
            return teams;
        },
        checkIfAllocationValueIsLarge(allocationValue) {
            if (allocationValue > 9) {
                return "9+";
            } else {
                return allocationValue;
            }
        },

        async getUsersLists() {
            this.isLoading = true;
            let url = `user/tenant/${this.tenantId}`;
            let items = null;
            try {
                let { data } = await axios.get(url);
                this.users = data;
            } catch (error) {
                console.log(error, "<<<<error while fetching users");
            }
            this.isLoading = false;
            return items;
        },

        async getGroups() {
            let items = [];
            try {
                this.isLoading = true;
                let { data } = await axios.get(`group/tenant/${this.tenantId}`);
                this.groups = data;
            } catch (error) {
                console.log("error", error);
            }
            this.isLoading = false;
            return items;
        },

        // Pagination

        async onPageChange(data, resetPage = false) {
            this.currentPageData = {
                page: data.currentPage,
                count: data.currentPerPage,
            };
            if (resetPage) this.currentPageData.page = 1;
            if(!resetPage) {
                await this.$nextTick();
                const scrollTarget = document.getElementById('vgt-table');
                if (scrollTarget) {
                  scrollTarget.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
            }
            this.clients = await this.fetchClientListsPost();
            this.columns = prePopulatedDropdownItems(this.columns, this.clients);
        },

        // Filter
        checkColumnConfig,
        async updateColumnFilters(column, value /* , callback */) {
            this.currentPageData.page = 1;
            if(this.selectedOptions[column.label]){
                this.selectedOptions = {...this.selectedOptions,[column.label]:[...value]};
            }
            else
                this.selectedOptions = {...this.selectedOptions,[column.label]:[...value]}
            
            this.paginationOptions.perPage = this.currentPageData.count;
            let selected = {
                key: column.filterOptions.query_key || column.field,
                value: column.filterOptions.multi ? 
                (column.field === 'allocated_teams' || column.field === 'allocated_users') ?  value.map((el) => el.id) : value.map((el) => el.name) : 
                (column.field === 'allocated_teams' || column.field === 'allocated_users') ? [value?.id] : [value?.name],
            };
            if (!selected.value?.length) {
                delete this.selectedDropDownItems[selected.key];
            } else {
                this.selectedDropDownItems = { ...this.selectedDropDownItems, [selected.key]: selected.value };
            }
            this.clients = await this.fetchClientListsPost();
        },
        async fetchClientListsPost() {
            this.isLoading = true;
            let url = `/tenant/clients`;
            let items = null;
            try {
                let body = {
                    tenant_id: this.tenantId,
                    ...this.selectedDropDownItems,
                    page: this.currentPageData.page,
                    count: this.currentPageData.count,
                };
                let { data } = await axios.post(url, body);
                items = data.data;
                this.pageData = data?.page;
            } catch (error) {
                console.log(error, "<<<<error while fetching users");
            }
            this.isLoading = false;
            return items;
        },
        searchFilter(column, event) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(column, event);
            }, delay);
        },
        async getSearchOptions(column, event) {
            let search = {
                key: column.filterOptions.query_key || column.field,
                value: event,
            };
            this.searchParams = { ...this.searchParams, [search.key]: search.value };
            let filteredDrowDowns = await this.fetchClients();
            this.columns = prePopulatedDropdownItems(this.columns, filteredDrowDowns);
        },

        async clearFilters() {
            this.paginationOptions.perPage = this.currentPageData.count;
            this.selectedOptions = {};
            this.currentPageData.page = 1;
            this.selectedDropDownItems = null;
            this.columns = this.columns.map((col) => {
                return { 
                    ...col, 
                    filterOptions: {
                        ...col.filterOptions,
                        selected: [],
                    }
                }
            });
            return (this.clients = await this.fetchClientListsPost());
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
.filter-dropdown {
    box-shadow: none !important;
    @apply border-transparent border  bg-white;
}
</style>
